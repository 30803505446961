import {Menu, MenuDivider, MenuItem} from "@blueprintjs/core";
import {light} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CardType} from "../views/JobAssignmentView";
import styled from "@emotion/styled";
import {CompactCardIcon, MinimumCardIcon, NormalCardIcon} from "./ViewChangerMenuIcons";
import {FaIcon} from "./common/SortMenuItem";
import {useContext} from "react";
import {FeatureFlagContext} from "../providers/FeatureFlagProvider";

type ViewChangerMenuProps = {
  selectedView: CardType;
  onViewChanged(viewType: CardType): void;
};

const ViewChangerMenu = ({selectedView, onViewChanged}: ViewChangerMenuProps) => {
  const {colorSort: colorSortEnable} = useContext(FeatureFlagContext);

  return colorSortEnable ? (
    <StyledMenu data-testid="new-viewchanger-menu">
      <MenuDivider title={<StyledMenuTitle>CARD SELECTOR</StyledMenuTitle>} />
      <MenuItem
        icon={<NormalCardIcon />}
        text="Detailed"
        data-testid="viewchanger-button-card"
        onClick={() => {
          onViewChanged(CardType.Detailed);
        }}
        labelElement={<FaIcon isVisible={selectedView === CardType.Detailed} />}
      />
      {
        <MenuItem
          icon={<CompactCardIcon />}
          text="Compact"
          data-testid="viewchanger-button-compact"
          onClick={() => {
            onViewChanged(CardType.Compact);
          }}
          labelElement={<FaIcon isVisible={selectedView === CardType.Compact} />}
        />
      }
      <MenuItem
        icon={<MinimumCardIcon />}
        text="Minimal"
        data-testid="viewchanger-button-mincard"
        onClick={() => {
          onViewChanged(CardType.Minimal);
        }}
        labelElement={<FaIcon isVisible={selectedView === CardType.Minimal} />}
      />
    </StyledMenu>
  ) : (
    <StyledMenu data-testid="viewchanger-menu">
      <MenuDivider title="View Selector" />
      <MenuItem
        icon={<FontAwesomeIcon icon={light("grid")} fixedWidth={true} size={"lg"} />}
        text={"Detailed"}
        data-testid="viewchanger-button-card"
        selected={selectedView === CardType.Detailed}
        onClick={() => {
          onViewChanged(CardType.Detailed);
        }}
      />
      {
        <MenuItem
          icon={<FontAwesomeIcon icon={light("grid-2")} fixedWidth={true} size={"lg"} />}
          text="Compact"
          data-testid="viewchanger-button-compact"
          onClick={() => {
            onViewChanged(CardType.Compact);
          }}
        />
      }
      <MenuItem
        icon={<FontAwesomeIcon icon={light("grid-4")} fixedWidth={true} size={"lg"} />}
        text={"Minimal"}
        data-testid="viewchanger-button-mincard"
        selected={selectedView === CardType.Minimal}
        onClick={() => {
          onViewChanged(CardType.Minimal);
        }}
      />
    </StyledMenu>
  );
};

export default ViewChangerMenu;

const StyledMenu = styled(Menu)`
  font-family: Roboto, sans-serif;
`;
const StyledMenuTitle = styled.div`
  font-weight: 400;
`;
