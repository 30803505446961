import {Intent, Position, Toaster} from "@blueprintjs/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import styled from "@emotion/styled";
import "./toaster.css";

const AppToasterTopLeft = Toaster.create({
  position: Position.TOP_LEFT,
  maxToasts: 3
});

const ToastContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const Body = styled.div``;

const Title = styled.div`
  font-size: 16px;
  font-weight: 500;
`;

type ToastProps = {
  message: string;
  title: string;
  intent: Intent;
};

const Toast = ({message, title, intent}: ToastProps) => {
  return (
    <ToastContainer>
      <Header>
        <FontAwesomeIcon
          size={"xl"}
          color={intent === Intent.SUCCESS ? "#32A467" : "#df4343"}
          icon={intent === Intent.SUCCESS ? solid("circle-check") : solid("triangle-exclamation")}
        />
        <Title>{title}</Title>
      </Header>
      <Body>{message}</Body>
    </ToastContainer>
  );
};

export const showToastWithTitle = ({
  intent,
  title,
  message,
  delay = 5000
}: {
  intent: Intent;
  title: string;
  message: string;
  delay?: number;
}) => {
  AppToasterTopLeft.show({
    intent: intent,
    message: <Toast message={message} title={title} intent={intent} />,
    className: "toast",
    timeout: delay
  });
};
