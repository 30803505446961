import {JobAssignmentSubmissionResult} from "./JobAssignmentEventHandler";
import {JobAssignmentUpdate, ManifestStop} from "../generated/graphql";
import {getArrayFromField} from "./General";

type PendingAssignment = {
  jobIds: number[];
  jobStopIds: number[];
  manifestDriverId: number;
  user: string;
  assignmentDateTime: number;
  status: JobAssignmentSubmissionResult;
};

const findAssignmentFromUpdate = (
  assignments: PendingAssignment[],
  update: JobAssignmentUpdate
): PendingAssignment | undefined => {
  return assignments.find((x) => findAssignmentFromUpdatePredicate(x, update));
};

const findAssignmentFromUpdatePredicate = (assignment: PendingAssignment, update: JobAssignmentUpdate) => {
  return (
    assignment.manifestDriverId === update.manifestDriverId &&
    (getArrayFromField(assignment.jobIds).includes(getArrayFromField(update.jobIds)[0]) ||
      getArrayFromField(assignment.jobStopIds).includes(getArrayFromField(update.jobStopIds)[0]))
  );
};

const createAssignmentFromUpdate = (update: JobAssignmentUpdate): PendingAssignment => {
  return {
    jobIds: update.jobIds as number[],
    jobStopIds: update.jobStopIds as number[],
    manifestDriverId: update.manifestDriverId as number,
    user: update.userName as string,
    assignmentDateTime: Date.now(),
    status: JobAssignmentSubmissionResult.PENDING
  };
};

const hasPendingActions = (assignments: PendingAssignment[] | undefined): boolean => {
  if (assignments === undefined) {
    return false;
  }
  return activePendingActions(assignments).length > 0;
};

const activePendingActions = (assignments: PendingAssignment[]): PendingAssignment[] => {
  return pendingActionsByStatus(assignments, [
    JobAssignmentSubmissionResult.PENDING,
    JobAssignmentSubmissionResult.SUBMISSION_SUCCESS
  ]);
};

const pendingActionsByStatus = (
  assignments: PendingAssignment[],
  status: JobAssignmentSubmissionResult[]
): PendingAssignment[] => {
  return assignments?.filter((pendingAction) => {
    return status.includes(pendingAction.status);
  });
};

const pendingActionsByManifestDriverId = (manifestDriverId: number, pendingActions: PendingAssignment[]) => {
  return pendingActions?.filter((action) => action.manifestDriverId === manifestDriverId);
};

const stopsInSuccessState = (assignments: PendingAssignment[] | undefined, stops: ManifestStop[]): number => {
  const pendingInSuccessState = assignments?.filter((pendingAction) => {
    return pendingAction.status === JobAssignmentSubmissionResult.ASSIGNMENT_SUCCESS;
  });
  const jobsInSuccessState = pendingInSuccessState
    ? pendingInSuccessState.flatMap((pendingAction) => pendingAction.jobIds)
    : [];
  return (jobsInSuccessState ? stops.filter((x: ManifestStop) => x && jobsInSuccessState.includes(x.job.jobId)) : [])
    .length;
};

export {
  findAssignmentFromUpdate,
  findAssignmentFromUpdatePredicate,
  createAssignmentFromUpdate,
  hasPendingActions,
  activePendingActions,
  stopsInSuccessState,
  pendingActionsByManifestDriverId
};
export type {PendingAssignment};
