import styled from "@emotion/styled";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {DispatchCardV3} from "../common/DispatchCard";

export const FaIcon = styled(FontAwesomeIcon)`
  color: #abb3bf;
`;

export const CardContainerV3 = styled.div`
  font-family: "Roboto", sans-serifs;

  * {
    box-sizing: border-box;
    padding: 0px;
    margin: 0;
  }
`;

type ManifestCardProps = {
  isSelected: boolean;
  isHighlighted?: boolean;
};

export const MinimalManifestCardV3 = styled(DispatchCardV3)<ManifestCardProps>`
  width: 240px;
  height: fit-content;
`;

export const ManifestCardV3 = styled(DispatchCardV3)<ManifestCardProps>`
  width: 320px;
  height: fit-content;
`;

export const ManifestCompactCard = styled(DispatchCardV3)<ManifestCardProps>`
  width: 240px;
  height: fit-content;
`;
