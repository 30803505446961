/* eslint-disable react-hooks/exhaustive-deps */
import CreateColorizedIndicators from "./CreateColorizedIndicators";
import ColorizedIndicatorsList from "./ColorizedIndicatorsList";
import {useCallback, useContext, useEffect} from "react";
import {
  SearchIndicatorConfigsDocument,
  SetIndicatorConfigMutationVariables,
  useSearchIndicatorConfigsQuery,
  useSetIndicatorConfigMutation
} from "../../../generated/graphql";
import {v4 as uuidv4} from "uuid";
import {isEqual} from "lodash";
import {showToastWithTitle} from "../../../utils/ToasterWithTitle";
import {UserColorProps} from "./types/userColorProps";
import {IndicatorLogicType} from "./types/indicatorLogicType";
import {IndicatorLogicSettings} from "./types/indicatorLogicSettings";
import {TriggerEventType} from "./types/triggerSelectorValues";
import SettingViewContainer from "../components/SettingViewContainer";
import SettingItemsContainer from "../components/SettingItemsContainer";
import {SettingItemEditorMode} from "../components/types/SettingItemEditorMode";
import {ColorizedIndicatorContext, ColorizedIndicatorDispatchContext} from "./ColorizedIndicatorStateProvider";
import {ConditionProperties} from "json-rules-engine";
import {defaultStepSize} from "./TriggerSelector";
import {getRankLogicsGroupedByColor} from "./ColorRank.service";

const IndicatorColors: UserColorProps = {
  red: {rank: 1, name: "Red", hex: "#B92B2B"},
  orange: {rank: 2, name: "Orange", hex: "#FC5D04"},
  yellow: {rank: 3, name: "Yellow", hex: "#E7A600"},
  olive: {rank: 4, name: "Olive", hex: "#798C00"},
  green: {rank: 5, name: "Green", hex: "#03A456"},
  turquoise: {rank: 6, name: "Turquoise", hex: "#008C9A"},
  blue: {rank: 8, name: "Blue", hex: "#164D74"},
  purple: {rank: 9, name: "Purple", hex: "#894BC1"},
  magenta: {rank: 10, name: "Magenta", hex: "#C925C2"},
  brown: {rank: 11, name: "Brown", hex: "#8C564B"},
  grey: {rank: 12, name: "Grey", hex: "#6D6D6D"},
  black: {rank: 13, name: "Black", hex: "#1E1E1E"}
};

enum OperationTypes {
  CREATE = "create",
  UPDATE = "update",
  DELETE = "delete"
}

const initialTrigger = {
  lowerType: TriggerEventType.BeforeEvent,
  lowerValue: 30,
  upperType: TriggerEventType.AfterEvent,
  upperValue: 15
};

const initialIndicatorLogic: IndicatorLogicType = {
  type: undefined,
  color: undefined,
  jobAttributes: {
    vehicleType: [],
    customer: [],
    service: [],
    stopStatus: undefined,
    site: []
  },
  orderPriority: [],
  manifestAttributes: {
    manifestStatus: undefined
  },
  comparison: undefined,
  trigger: {
    ...initialTrigger
  },
  rule: {
    conditions: {all: [] as ConditionProperties[]},
    event: {} as Event
  },
  key: "",
  rank: 0
};

const ColorizedIndicators = () => {
  const {data, loading, error} = useSearchIndicatorConfigsQuery({
    variables: {
      filter: {
        siteId: {
          eq: 0
        }
      }
    }
  });
  const [setIndicatorConfigMutation] = useSetIndicatorConfigMutation();

  const colorizedState = useContext(ColorizedIndicatorContext);
  const colorizedDispatch = useContext(ColorizedIndicatorDispatchContext);

  const addNewLogicHandler = useCallback(() => {
    colorizedDispatch({type: "SET_SELECTED_LOGIC", payload: initialIndicatorLogic});
    colorizedDispatch({type: "SET_FORM_MODE", payload: SettingItemEditorMode.Create});
  }, []);

  const cancelHandler = useCallback(() => {
    colorizedDispatch({type: "SET_SELECTED_LOGIC", payload: initialIndicatorLogic});
    colorizedDispatch({type: "SET_TRIGGER_SELECTOR_STEP_SIZE", payload: defaultStepSize});
    colorizedDispatch({type: "SET_FORM_MODE", payload: SettingItemEditorMode.Disable});
  }, []);

  const editHandler = useCallback((logic: IndicatorLogicType) => {
    colorizedDispatch({type: "SET_FORM_MODE", payload: SettingItemEditorMode.Edit});
    colorizedDispatch({type: "SET_BEFORE_EDIT_LOGIC", payload: logic});
    colorizedDispatch({type: "SET_TRIGGER_SELECTOR_STEP_SIZE", payload: defaultStepSize});
    colorizedDispatch({
      type: "SET_SELECTED_LOGIC",
      payload: logic
    });
  }, []);

  useEffect(() => {
    let isComponentMounted = true;
    if (isComponentMounted) {
      colorizedDispatch({
        type: "SET_IS_EDITING_LOGIC_CHANGED",
        payload: !isEqual(colorizedState.beforeEditLogic, colorizedState.selectedLogic)
      });
    }
    return () => {
      isComponentMounted = false;
    };
  }, [colorizedState.beforeEditLogic, colorizedState.selectedLogic]);

  const updateIndicatorState = useCallback(
    (data: IndicatorLogicType, getRules, operation?: OperationTypes) => {
      let updatedData = {};
      let operationType: OperationTypes;

      const distinctLogicList = colorizedState.logicData?.rules.reduce(
        (acc: IndicatorLogicType[], logic: IndicatorLogicType) => {
          const existingLogic = acc.find((l) => l.color === logic.color);
          if (!existingLogic) {
            acc.push(logic);
          }
          return acc;
        },
        []
      );

      if (colorizedState.logicData && colorizedState.logicData?.rules.findIndex((rule) => rule.key === data.key) >= 0) {
        operationType = operation ?? OperationTypes.UPDATE;

        //re-rank grouping by color
        const rules = getRankLogicsGroupedByColor(getRules());

        updatedData = {
          ...colorizedState.logicData,
          rules: rules
        } as IndicatorLogicSettings;
      } else {
        //new rule, add it.
        operationType = OperationTypes.CREATE;
        data.key = uuidv4();

        //set rank to an existing color rank or to the next rank if no match exists
        let rank = 1;
        if (distinctLogicList) {
          const indexOfExistingRankedColor = distinctLogicList.findIndex((rule) => rule.color === data.color);
          if (indexOfExistingRankedColor >= 0) {
            rank = distinctLogicList[indexOfExistingRankedColor].rank;
          } else {
            rank = distinctLogicList.length + 1;
          }
        }
        data.rank = rank;
        updatedData = {
          ...colorizedState.logicData,
          rules: colorizedState.logicData ? [...colorizedState.logicData.rules, data] : [data]
        } as IndicatorLogicSettings;
      }
      const variables: SetIndicatorConfigMutationVariables = {
        input: {
          config: JSON.stringify(updatedData),
          siteId: 0 //TODO: Implement this with stations
        }
      };

      if (colorizedState.logicData?.sk) {
        variables["sk"] = colorizedState.logicData?.sk;
      }

      setIndicatorConfigMutation({
        refetchQueries: [SearchIndicatorConfigsDocument],
        variables: variables
      })
        .then((result) => {
          if (result.data) {
            switch (operationType) {
              case OperationTypes.CREATE:
                return showToastWithTitle({
                  intent: "success",
                  title: "Success",
                  message: "New Colorized Indicator Logic has been created!"
                });

              case OperationTypes.UPDATE:
                return showToastWithTitle({
                  intent: "success",
                  title: "Success",
                  message: "Colorized Indicators' Logic has been updated!"
                });
              case OperationTypes.DELETE:
                return showToastWithTitle({
                  intent: "success",
                  title: "Success",
                  message: "Colorized Indicators' Logic has been deleted!"
                });
              default:
            }
          }
          if (result.errors) {
            return showToastWithTitle({
              intent: "danger",
              title: "Error",
              message: "Something went wrong. Please try again later!"
            });
          }
        })
        .catch((error) => {
          console.warn(error);
        });

      colorizedDispatch({type: "SET_SELECTED_LOGIC", payload: initialIndicatorLogic});
      colorizedDispatch({type: "SET_FORM_MODE", payload: SettingItemEditorMode.Disable});
    },
    [colorizedState.logicData, setIndicatorConfigMutation]
  );

  const saveLogicHandler = useCallback(
    (data: IndicatorLogicType) => {
      updateIndicatorState(data, () => {
        return colorizedState.logicData?.rules.map((rule) => {
          if (rule.key === data.key) {
            return data;
          }
          return rule;
        });
      });
    },
    [colorizedState.logicData?.rules, updateIndicatorState]
  );

  const deleteLogicHandler = useCallback(
    (data: IndicatorLogicType) => {
      updateIndicatorState(
        data,
        () => {
          //remove rule
          const updateList = colorizedState.logicData?.rules.filter((rule) => rule.key !== data.key);

          const groupedAndRanked = getRankLogicsGroupedByColor(updateList as IndicatorLogicType[]);
          return groupedAndRanked;
        },
        OperationTypes.DELETE
      );
    },
    [colorizedState.logicData?.rules, updateIndicatorState]
  );

  const onChangeRankHandler = useCallback(
    (data: IndicatorLogicType[]) => {
      const updatedData = {
        ...colorizedState.logicData,
        rules: data
      } as IndicatorLogicSettings;

      const variables: SetIndicatorConfigMutationVariables = {
        input: {
          config: JSON.stringify(updatedData),
          siteId: 0 //TODO: Implement this with stations
        }
      };

      if (colorizedState.logicData?.sk) {
        variables["sk"] = colorizedState.logicData?.sk;
      }

      setIndicatorConfigMutation({
        refetchQueries: [SearchIndicatorConfigsDocument],
        variables: variables
      });
    },
    [colorizedState.logicData, setIndicatorConfigMutation]
  );

  useEffect(() => {
    let isComponentMounted = true;

    if (isComponentMounted) {
      if (!error && !loading && data?.searchIndicatorConfigs?.items && data.searchIndicatorConfigs.items.length > 0) {
        const newLogicData: IndicatorLogicSettings = JSON.parse(
          data.searchIndicatorConfigs.items[0]?.config
        ) as IndicatorLogicSettings;
        colorizedDispatch({
          type: "SET_LOGIC_DATA",
          payload: {...newLogicData, sk: data.searchIndicatorConfigs.items[0].sk}
        });
      } else if (error) {
        console.error("Error loading Indicator Logic.", error, error.graphQLErrors);
      }
    }
    return () => {
      isComponentMounted = false;
    };
  }, [data, loading, error]);

  return (
    <SettingViewContainer title={"Colorized Indicators' Settings"}>
      <CreateColorizedIndicators
        selectedLogic={colorizedState.selectedLogic as IndicatorLogicType}
        onSave={saveLogicHandler}
        onDelete={deleteLogicHandler}
        onAddNew={addNewLogicHandler}
        onCancel={cancelHandler}
        formMode={colorizedState.formMode}
        isEditingLogicChanged={colorizedState.isEditingLogicChanged}
      />
      <SettingItemsContainer title={"Evaluation Precedence"}>
        {!loading && <ColorizedIndicatorsList onSelectLogicHandler={editHandler} onChangeRank={onChangeRankHandler} />}
      </SettingItemsContainer>
    </SettingViewContainer>
  );
};

export default ColorizedIndicators;
export {IndicatorColors};
