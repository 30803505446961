import React from "react";
import {Icon, MenuItem, Text} from "@blueprintjs/core";
import {ItemListRenderer, ItemRenderer, Select2} from "@blueprintjs/select";
import {ManifestStatusTypes} from "../settings/ColorizedIndicators/types/manifestAttributes";
import {ActiveIcon, FutureIcon, InactiveIcon} from "./manifest-filter/ManifestIcons";
import {EmptyValueStrings} from "../common/Constants";
import {StyledList} from "./details/components/StopLocationSelector";
import styled from "@emotion/styled";
import {DetailButton} from "./details/ManifestDetail.styles";

interface ManifestStatusSeletorProps {
  onSelectStatus: (status: ManifestStatusTypes) => void;
  selectedStatus: string | undefined;
  minimal?: boolean;
}

const getStatusText = (manifestStatusType: string | undefined, minimal: boolean) => {
  let text: string = EmptyValueStrings.unknown;
  switch (manifestStatusType) {
    case ManifestStatusTypes.Active:
      text = "Active";
      break;
    case ManifestStatusTypes.Future:
      text = "Future";
      break;
    case ManifestStatusTypes.Inactive:
      text = "Inactive";
      break;
    default:
      break;
  }
  return minimal ? text.charAt(0) : text;
};

const getStatusIcon = (manifestStatusType: string | undefined) => {
  switch (manifestStatusType) {
    case ManifestStatusTypes.Active:
      return <ActiveIcon />;
    case ManifestStatusTypes.Future:
      return <FutureIcon />;
    case ManifestStatusTypes.Inactive:
      return <InactiveIcon />;
    default:
      return null;
  }
};

const getStatusTextColor = (manifestStatusType: string | undefined, minimal: boolean) => {
  if (!minimal) return "#797979";
  switch (manifestStatusType) {
    case ManifestStatusTypes.Active:
      return "#32A467";
    case ManifestStatusTypes.Future:
      return "#14305A";
    case ManifestStatusTypes.Inactive:
      return "#6B7280";
    default:
      return "#797979";
  }
};

const itemRenderer: ItemRenderer<ManifestStatusTypes> = (status, {handleClick, handleFocus, modifiers}) => {
  return (
    <MenuItem
      data-testid="status-item"
      active={modifiers.active}
      disabled={modifiers.disabled}
      key={status}
      onClick={handleClick}
      onFocus={handleFocus}
      icon={getStatusIcon(status)}
      text={getStatusText(status, false)}
      labelElement={modifiers.active && <Icon icon="tick" />}
    />
  );
};

const itemListRenderer: ItemListRenderer<ManifestStatusTypes> = ({items, renderItem, itemsParentRef}) => (
  <>
    <StyledText>STATUS</StyledText>
    <StyledList data-testid="manifest-status-list" ref={itemsParentRef}>
      {items.map(renderItem)}
    </StyledList>
  </>
);

const ManifestStatusSeletor = ({onSelectStatus, selectedStatus, minimal = false}: ManifestStatusSeletorProps) => {
  return (
    <Select2<ManifestStatusTypes>
      items={[ManifestStatusTypes.Active, ManifestStatusTypes.Future, ManifestStatusTypes.Inactive]}
      onItemSelect={onSelectStatus}
      activeItem={
        [ManifestStatusTypes.Active, ManifestStatusTypes.Future, ManifestStatusTypes.Inactive].includes(
          selectedStatus as any
        )
          ? (selectedStatus as ManifestStatusTypes)
          : null
      }
      filterable={false}
      popoverProps={{minimal: true, popoverClassName: `manifest-status-popover ${minimal ? "minimal" : ""}`}}
      itemRenderer={itemRenderer}
      itemListRenderer={!minimal ? itemListRenderer : undefined}
    >
      <DetailButton
        data-testid="manifest-status"
        minimal
        cutomMinimal={minimal}
        icon={!minimal && getStatusIcon(selectedStatus)}
        rightIcon={<Icon size={minimal ? 14 : 16} icon={minimal ? "caret-down" : "chevron-down"} />}
        color={getStatusTextColor(selectedStatus, minimal)}
      >
        {getStatusText(selectedStatus, minimal)}
      </DetailButton>
    </Select2>
  );
};

export default ManifestStatusSeletor;

const StyledText = styled(Text)`
  font-family: "Roboto", sans-serif;
  color: #797979;
  font-weight: 500;
  margin-bottom: 4px;
  margin-left: 8px;
  font-size: 12px;
`;
