import {UserClaims} from "@okta/okta-auth-js";
import {useOktaAuth} from "@okta/okta-react";
import {createContext, useContext, useEffect, useState} from "react";
import {getTenant, Tenant} from "../services/TenantService";

type AuthState = {
  user?: UserClaims;
  token?: string;
  isAdmin?: boolean;
  isSuperUser?: boolean;
  tenantId?: string;
  tenant?: Tenant;
  groups?: string[];
  tenants?: string[];
  isEcourierUser?: boolean;
  expiresAt?: number;
  isAuthenticated?: boolean;
};

const AuthContext = createContext<AuthState>({
  user: undefined,
  token: undefined,
  isAdmin: undefined,
  isSuperUser: undefined,
  tenantId: undefined,
  tenant: undefined,
  groups: undefined,
  tenants: undefined,
  isEcourierUser: undefined,
  expiresAt: undefined,
  isAuthenticated: undefined
});

type AuthProviderProps = {
  children: JSX.Element[] | JSX.Element;
};

const AuthProvider = ({children}: AuthProviderProps) => {
  const {oktaAuth, authState} = useOktaAuth();
  const [state, setState] = useState<AuthState>({
    user: undefined,
    token: undefined,
    isAdmin: undefined,
    tenantId: undefined,
    tenant: undefined,
    groups: undefined,
    tenants: undefined,
    isEcourierUser: undefined,
    expiresAt: undefined,
    isAuthenticated: undefined
  });

  useEffect(() => {
    let cancel = false;

    const getUserClaims = async () => {
      console.debug("AuthProvider - Retrieving user claims");
      const authenticatedUser = await oktaAuth.getUser();
      console.debug(`AuthProvider - User claims retrieved: ${JSON.stringify(authenticatedUser)}`);
      if (!cancel) {
        console.debug("AuthProvider - Setting user claims");
        setState((prevState) => ({
          ...prevState,
          user: authenticatedUser
        }));
        console.debug("AuthProvider - User claims set");
      } else {
        console.warn("AuthProvider - User claims process canceled!");
      }
    };

    if (state.token) {
      console.debug(`AuthProvider - ${new Date()}`);
      getUserClaims();
    } else {
      console.debug("AuthProvider - User token not yet set");
    }

    return () => {
      cancel = true;
    };
  }, [oktaAuth, state.token]);

  useEffect(() => {
    let cancel = false;

    const getUserAttributes = async () => {
      if (!cancel) {
        console.debug(`AuthProvider - ${new Date()}`);
        console.debug(`AuthProvider - Updating user attributes based on ${JSON.stringify(authState)}`);
        const authenticationToken = authState?.accessToken?.accessToken ? authState?.accessToken?.accessToken : "";
        const claims = authState?.accessToken?.claims;
        const groupsClaim = claims?.groups as unknown as Array<string>;
        const tenantsClaim = claims?.allowedTenants as unknown as Array<string>;
        const tenantId = authState?.accessToken?.claims?.tenantId as string;
        const isEcourierUser = authState?.accessToken?.claims?.isEcourierUser as boolean;
        const expiresAt = authState?.accessToken?.expiresAt as number;
        const isAuthenticated = authState?.isAuthenticated as boolean;

        if (tenantId) {
          getTenant(authenticationToken, tenantId)
            .then((tenantResp) => {
              setState((prevState) => ({
                ...prevState,
                tenant: tenantResp.data
              }));
            })
            .catch((e1) => {
              console.error("AuthProvider - Failed to set active tenant", e1);
            });
        }
        const newState: AuthState = {
          token: authenticationToken,
          isSuperUser: groupsClaim?.includes("dispatch-admins"),
          isAdmin: groupsClaim?.some((x) => ["dispatch-admins", "dispatch-tenant-admins"].includes(x)) || false,
          tenantId: tenantId,
          groups: groupsClaim,
          tenants: tenantsClaim,
          isEcourierUser: isEcourierUser,
          expiresAt: expiresAt,
          isAuthenticated: isAuthenticated
        };
        console.debug(`AuthProvider - Setting user attributes: ${JSON.stringify(newState)}`);
        setState((prevState) => ({
          ...prevState,
          ...newState
        }));
      } else {
        console.warn("AuthProvider - User attributes process canceled!");
      }
    };

    getUserAttributes();

    return () => {
      cancel = true;
    };
  }, [authState]);

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
export type {AuthState};
export {AuthContext};

export const useAuthState = () => useContext(AuthContext);
