import {ApolloError} from "@apollo/client";
import {Intent} from "@blueprintjs/core";
import {UserClaims} from "@okta/okta-auth-js";
import React from "react";
import {AppToaster} from "./toaster";
import {JobAssignmentActions} from "../components/common/JobAssignmentReducer";
import {JobAssignmentUpdate} from "../generated/graphql";

type JobAssignmentEventHandlerProps = {
  update: JobAssignmentUpdate | undefined | null;
  dispatch: React.Dispatch<JobAssignmentActions>;
  error?: ApolloError;
  userClaims: UserClaims;
};

enum JobAssignmentSubmissionResult {
  PENDING,
  SUBMISSION_SUCCESS,
  SUBMISSION_FAILED,
  ASSIGNMENT_SUCCESS,
  ASSIGNMENT_FAILED
}

const isTerminalResult = (result: JobAssignmentSubmissionResult): boolean => {
  return [
    JobAssignmentSubmissionResult.ASSIGNMENT_FAILED,
    JobAssignmentSubmissionResult.SUBMISSION_FAILED,
    JobAssignmentSubmissionResult.ASSIGNMENT_SUCCESS
  ].includes(result);
};

const JobAssignmentEventHandler = ({update, error, dispatch, userClaims}: JobAssignmentEventHandlerProps) => {
  console.debug("JobAssignmentEventHandler ~ update:", update);
  if (update) {
    if (update.userName === userClaims.email) {
      if (update.submissionResult === JobAssignmentSubmissionResult[JobAssignmentSubmissionResult.ASSIGNMENT_FAILED]) {
        reportError(`Error assigning job id(s): ${update.jobIds?.join(", ")}`);
      } else if (
        update.submissionResult === JobAssignmentSubmissionResult[JobAssignmentSubmissionResult.SUBMISSION_FAILED]
      ) {
        reportError(`Error submitting assignment for job id(s): ${update.jobIds?.join(", ")}`);
      }
    }
    dispatch({type: "UpdatePendingActionsStatus", payload: update});
    console.debug(`UpdatePendingActionsStatus: ${JSON.stringify(update)}`);
    dispatch({type: "PruneTerminalPendingActions"});
  }

  if (error) {
    reportError("There was an error handling a job assignment update", error);
  }
};

const reportError = (msg: string, error?: any) => {
  console.error(`${msg}${error ? ` ${JSON.stringify(error)}` : ""}`);
  AppToaster.show({
    intent: Intent.WARNING,
    icon: "warning-sign",
    message: msg
  });
};

export {JobAssignmentEventHandler, isTerminalResult};
export {JobAssignmentSubmissionResult};
