import React from "react";
import {Button, MenuItem, Text} from "@blueprintjs/core";
import {ItemPredicate, ItemRenderer, Select2} from "@blueprintjs/select";
import styled from "@emotion/styled";
import {renderStopTypeIcon} from "../../StopTypeIcons";

export type TStopLocation = {
  key: number;
  address: string;
  name: string;
  stopType: string;
};

interface IStopLocationSelectorProps {
  items: TStopLocation[];
  selectedItem: TStopLocation | undefined;
  onSelectItem: (item: TStopLocation) => void;
}

const StopLocationSelector = ({items, selectedItem, onSelectItem}: IStopLocationSelectorProps) => {
  return (
    <Select2
      popoverProps={{
        matchTargetWidth: true,
        minimal: true,
        placement: "bottom",
        popoverClassName: "stop-location-dropdown"
      }}
      itemRenderer={renderItem}
      itemPredicate={filterItem}
      activeItem={selectedItem}
      inputProps={{placeholder: "Search for a location"}}
      items={items}
      onItemSelect={onSelectItem}
      noResults={<MenuItem disabled={true} text="No results." roleStructure="listoption" />}
      resetOnClose
      itemListRenderer={({items, renderItem, query, itemsParentRef}) => (
        <>
          {!query && (
            <SortStatus>
              <SortedIcon />
              MANIFEST SEQUENCE
            </SortStatus>
          )}
          <StyledList
            data-testid="stop-location-list"
            ref={itemsParentRef}
            style={{maxHeight: "30vh", overflowY: "auto"}}
          >
            {items.map(renderItem)}
          </StyledList>
        </>
      )}
    >
      <StyledButton
        data-testid="stop-location-selector"
        text={
          selectedItem ? (
            <SelectedContent>
              {renderStopTypeIcon(selectedItem.stopType)}
              <StyledText ellipsize>{selectedItem.address}</StyledText>
            </SelectedContent>
          ) : (
            "Select a location"
          )
        }
        rightIcon="chevron-down"
      ></StyledButton>
    </Select2>
  );
};

export default StopLocationSelector;

const renderItem: ItemRenderer<TStopLocation> = (item, {handleClick, handleFocus, modifiers}) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }

  return (
    <MenuItem
      data-testid="stop-location-item"
      active={modifiers.active}
      disabled={modifiers.disabled}
      key={item.key}
      onClick={handleClick}
      onFocus={handleFocus}
      roleStructure="listoption"
      text={
        <>
          {item.address}
          {item.name && (
            <>
              <span> | </span>
              <span style={{color: "#797979"}}>{item.name}</span>
            </>
          )}
        </>
      }
      icon={renderStopTypeIcon(item.stopType)}
    />
  );
};

const filterItem: ItemPredicate<TStopLocation> = (query, item) => {
  const stopAddress = item.address?.toLowerCase();
  const stopName = item.name?.toLowerCase();
  const normalizedQuery = query.trim().toLocaleLowerCase();
  const regx = new RegExp(normalizedQuery);
  return Boolean(regx.exec(stopName)) || Boolean(regx.exec(stopAddress));
};

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 4px;

  &.bp4-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    border-radius: 4px;
    box-shadow: 0px 1px 1px 0px #00000026;
    line-height: 20px;
    padding: 6px 10px;

    :hover {
      border: none;
      box-shadow: 0px 1px 1px 0px #00000026;
      opacity: 0.9;
    }
  }
`;

const SelectedContent = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  overflow: hidden;

  svg {
    width: 20px;
    height: 20px;
  }
`;

const StyledText = styled(Text)`
  max-width: 330px;
  transform: translateY(1px);
  overflow: hidden;
`;

const SortStatus = styled.div`
  padding: 8px;
  font-family: "Roboto", sans-serif;
  color: #797979;
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const StyledList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 4px;

  li {
    border-radius: 4px;
    font-family: "Roboto", sans-serif;

    .bp4-fill {
      transform: translateY(1px);
    }
  }
`;

const SortedIcon = () => (
  <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.73764 13.8584C4.54701 14.0672 4.28139 14.1875 4.00014 14.1875C3.71889 14.1875 3.45326 14.0672 3.26264 13.8584L0.512637 10.8209C0.140762 10.4096 0.165762 9.76726 0.575137 9.39073C0.984512 9.01421 1.61576 9.03952 1.98764 9.45401L3.00014 10.5709V1.02495C3.00014 0.464912 3.44701 0.0124512 4.00014 0.0124512C4.55326 0.0124512 5.00014 0.464912 5.00014 1.02495V10.5709L6.01264 9.45085C6.38451 9.03952 7.01889 9.01104 7.42514 9.38757C7.83139 9.76409 7.85951 10.4064 7.48764 10.8177L4.73764 13.8552V13.8584ZM10.0001 14.1875C9.44701 14.1875 9.00014 13.735 9.00014 13.175C9.00014 12.6149 9.44701 12.1625 10.0001 12.1625H11.0001C11.5533 12.1625 12.0001 12.6149 12.0001 13.175C12.0001 13.735 11.5533 14.1875 11.0001 14.1875H10.0001ZM10.0001 10.1375C9.44701 10.1375 9.00014 9.68499 9.00014 9.12495C9.00014 8.56491 9.44701 8.11245 10.0001 8.11245H13.0001C13.5533 8.11245 14.0001 8.56491 14.0001 9.12495C14.0001 9.68499 13.5533 10.1375 13.0001 10.1375H10.0001ZM10.0001 6.08745C9.44701 6.08745 9.00014 5.63499 9.00014 5.07495C9.00014 4.51491 9.44701 4.06245 10.0001 4.06245H15.0001C15.5533 4.06245 16.0001 4.51491 16.0001 5.07495C16.0001 5.63499 15.5533 6.08745 15.0001 6.08745H10.0001ZM10.0001 2.03745C9.44701 2.03745 9.00014 1.58499 9.00014 1.02495C9.00014 0.464912 9.44701 0.0124512 10.0001 0.0124512H17.0001C17.5533 0.0124512 18.0001 0.464912 18.0001 1.02495C18.0001 1.58499 17.5533 2.03745 17.0001 2.03745H10.0001Z"
      fill="#797979"
    />
  </svg>
);
