import {Job, JobStopManifestSequence, Manifest, ManifestStop, Maybe, NestedJobStop} from "../generated/graphql";
import {sequenceComparator} from "./JobStopService";
import {getJobStopTime, getLocalTime} from "../utils/DateUtils";
import {ManifestStopEnhanced} from "../components/manifest/details/ManifestDetailsV2";
import {TopStopInformationValue} from "../components/manifest/card-configuration/ManifestConfigurationTypes";
import {clone} from "lodash";
import {EmptyValueStrings} from "../components/common/Constants";

export enum JobStopStatus {
  ACTIVE = "A",
  COMPLETED = "C",
  QUEUED = "Q"
}

export enum JobStopType {
  PICKUP = "P",
  DELIVERY = "D",
  BOTH = "B"
}

export const jobStopTypeToString = ({value}: {value: string}) => {
  switch (value) {
    case JobStopType.PICKUP:
      return "Pickup";
    case JobStopType.DELIVERY:
      return "Delivery";
    case JobStopType.BOTH:
      return "Both";
    default:
      return "";
  }
};

export const jobStopStatusToString = (jobStopStatus: any) => {
  switch (jobStopStatus) {
    case JobStopStatus.COMPLETED:
      return "Completed";
    case JobStopStatus.ACTIVE:
      return "Active";
    case JobStopStatus.QUEUED:
      return "Queued";
    default:
      return "";
  }
};

export const activeStops = (stops: readonly ManifestStop[] | Maybe<ManifestStop[]> | undefined): number =>
  stops?.filter(isActiveOrQueued).length ?? 0;

export const completedStops = (stops: readonly ManifestStop[] | Maybe<ManifestStop[]> | undefined): number =>
  stops?.filter(isCompleted).length ?? 0;

export const isActiveOrQueued = (stop: ManifestStop): boolean =>
  stop.jobStopStatus === JobStopStatus.ACTIVE || stop.jobStopStatus === JobStopStatus.QUEUED;

export const hasQueuedStops = (stops: readonly ManifestStop[] | Maybe<ManifestStop[]> | undefined): boolean =>
  stops?.some(isQueued) ?? false;

export const isCompleted = (stop: ManifestStop | NestedJobStop): boolean =>
  stop.jobStopStatus === JobStopStatus.COMPLETED;

export const isPartiallyCompletedJob = (job: Job): boolean =>
  job.stops.some(isCompleted) && !job.stops.every(isCompleted);

export const isOnSite = (stop: ManifestStop): boolean => {
  if (stop.arriveDateTime && !stop.departDateTime && !isCompleted(stop)) {
    return true;
  }
  return false;
};
export const isQueued = (stop: ManifestStop): boolean => stop.jobStopStatus === JobStopStatus.QUEUED;

export const getNextActiveStop = (manifest: Manifest): ManifestStop | undefined => {
  return getNextActiveStops(manifest, 1)?.[0] || undefined;
};
export const getNextActiveStops = (manifest: Manifest, stops: number): ManifestStop[] | undefined => {
  return manifest.stops?.filter(isActiveOrQueued).sort(sequenceComparator).slice(0, stops);
};

export const getAllActiveStops = (manifest: Manifest): ManifestStop[] | undefined => {
  return manifest.stops?.filter(isActiveOrQueued).sort(sequenceComparator);
};

export const getFinalCompletedStop = (manifest: Manifest): ManifestStop | undefined => {
  if (manifest.stops?.every(isCompleted)) {
    return [...manifest.stops].sort(sequenceComparator)[manifest.stops?.length - 1];
  }
  return undefined;
};

export const isStopsAreSameJob = (stops: ManifestStop[]): boolean => {
  return stops.every((stop) => stop.job.jobId === stops[0].job.jobId);
};

export const getTopStopByConfig = (
  manifest: Manifest,
  topStopInfo: TopStopInformationValue
): ManifestStop | undefined => {
  switch (topStopInfo) {
    case TopStopInformationValue.LastCompletedStop: {
      return getLowestRankCompletedStop(manifest);
    }

    case TopStopInformationValue.FirstStopInManifest: {
      return getFirstStop(manifest);
    }

    case TopStopInformationValue.NextStopInManifest: {
      return getNextActiveStops(manifest, 1)?.at(0);
    }

    default:
  }
};

export const getOnSiteStop = (manifest: Manifest): ManifestStop | undefined => {
  return manifest.stops?.find(isOnSite);
};

export const getLowestRankCompletedStop = (manifest: Manifest): ManifestStop | undefined => {
  return manifest.stops?.filter(isCompleted).sort(sequenceComparator).at(-1);
};

export const getFirstStop = (manifest: Manifest): ManifestStop | undefined => {
  return clone(manifest.stops)?.sort(sequenceComparator).at(0);
};

export const getTimeStampValue = (
  stop: ManifestStop,
  timezoneOfJobStop: boolean,
  isTimeFormat24hr: boolean
): string => {
  let time = stop.scheduledDateTime;

  if (isOnSite(stop)) {
    time = stop.arriveDateTime;
  }

  if (isCompleted(stop)) {
    time = stop.departDateTime ?? stop.scheduledDateTime;
  }

  const timeStr = timezoneOfJobStop
    ? getJobStopTime(time, stop.timeZone, isTimeFormat24hr, {amPmFormat: "aaa"})
    : getLocalTime(time, stop.timeZone, isTimeFormat24hr, {amPmFormat: "aaa"});

  if (timeStr) {
    return timeStr;
  }
  return EmptyValueStrings.notAvailable;
};

export const getStopsByNewSequences = (sequences: JobStopManifestSequence[], manifest: Manifest) => {
  if (sequences.length === 0) return undefined;
  const manifestStops = manifest.stops;
  const newManifestStops: ManifestStopEnhanced[] = [];
  sequences.forEach((sequence) => {
    const stop = manifestStops?.find((stop) => stop.jobStopId === sequence.jobStopId);
    if (stop) {
      newManifestStops.push({...stop, optimizedSequence: sequence.manifestSequence});
    }
  });
  const remainingStops =
    manifestStops?.filter((s) => !newManifestStops.some((ns) => ns.jobStopId === s.jobStopId)) ?? [];

  return newManifestStops.concat(remainingStops);
};
