import {MenuItemDef, GetContextMenuItemsParams} from "@ag-grid-community/core";
import {ManifestDetailsContext, ManifestStopEnhanced} from "../ManifestDetailsV2";
import {
  isActiveOrQueued,
  isCompleted,
  isPartiallyCompletedJob,
  isStopsAreSameJob
} from "../../../../services/ManifestStopService";
import {ManifestStop} from "../../../../generated/graphql";

export enum GridMenuContextText {
  UnassignJobs = "Unassign Jobs",
  UnassignJob = "Unassign Job",
  MarkStopComplete = "Mark stop complete",
  MarkStopIncomplete = "Mark stop incomplete",
  ReassignJob = "Reassign Job",
  ReassignJobs = "Reassign Jobs",
  ReassignPartlyCompleteJob = "Reassign Partly Complete Job",
  AdjustJobStopTime = "Adjust Job's Stop Time",
  MultipleJobsSelected = "Multiple Jobs Selected"
}

export const disabledWhenCanUnassignPartialJob = (selectedStops: ManifestStop[], manifestStops: ManifestStop[]) => {
  const selectedJobIds = [...new Set(selectedStops.map((ms) => ms.job.jobId))];
  const jobsBySelectedIds = new Map();
  selectedJobIds.forEach((jobId) => {
    const allStopsByJobIds = manifestStops.filter((stop) => stop.job.jobId === jobId);
    jobsBySelectedIds.set(jobId, allStopsByJobIds);
  });

  for (const [, stops] of jobsBySelectedIds.entries()) {
    if (stops.every(isCompleted)) {
      return true; // If all stops for this job are completed, return true
    }
  }
  return false; // If no job has all stops completed, return false
};

const getUnassignJobMenuItemDef = (params: GetContextMenuItemsParams<ManifestStopEnhanced>): MenuItemDef => {
  const manifestDetailsContext = params.context as ManifestDetailsContext;
  if (manifestDetailsContext.selectedStops.length >= 1) {
    const selectedJobIds = manifestDetailsContext.selectedStops.map((ms) => ms.job.jobId);
    const arrayOfJobStopIds = manifestDetailsContext.selectedStops.map((ms) => ms.jobStopId);
    const arrayOfJobNumbers = manifestDetailsContext.selectedStops.map((ms) => ms.job.jobNumber);
    const findAllJobContainsCompleted = manifestDetailsContext.manifest.stops?.filter((ms) => {
      return selectedJobIds.includes(ms.job.jobId) && isCompleted(ms);
    });

    const disableUnassignJobs = manifestDetailsContext.canUnassignPartialJob
      ? disabledWhenCanUnassignPartialJob(
          manifestDetailsContext.selectedStops,
          manifestDetailsContext.manifest.stops as ManifestStop[]
        )
      : findAllJobContainsCompleted?.length !== 0;
    return {
      name:
        manifestDetailsContext.selectedStops.length > 1 && !isStopsAreSameJob(manifestDetailsContext.selectedStops)
          ? GridMenuContextText.UnassignJobs
          : GridMenuContextText.UnassignJob,
      action: () =>
        manifestDetailsContext.handleUnassignJob({
          jobStopIds: arrayOfJobStopIds,
          jobNumbers: arrayOfJobNumbers,
          jobIds: selectedJobIds
        }),
      disabled: disableUnassignJobs
    };
  } else {
    const jobId = params.node?.data?.job.jobId;
    const jobStopId = params.node?.data?.jobStopId;
    let unassignJobDisabled = false;
    if (jobId && jobStopId) {
      unassignJobDisabled = manifestDetailsContext.canUnassignPartialJob
        ? disabledWhenCanUnassignPartialJob(
            [params?.node?.data as ManifestStopEnhanced],
            manifestDetailsContext.manifest.stops as ManifestStop[]
          )
        : manifestDetailsContext.manifest.stops!.some((ms) => {
            return ms.job.jobId === jobId && isCompleted(ms);
          });
    }
    return {
      name: GridMenuContextText.UnassignJob,
      action: () =>
        manifestDetailsContext.handleUnassignJob({
          jobStopIds: [(params?.node?.data?.jobStopId as number) ?? 0],
          jobNumbers: [(params?.node?.data?.job.jobNumber as string) ?? ""],
          jobIds: [jobId as number]
        }),
      disabled: unassignJobDisabled
    };
  }
};

const getMarkJobCompleteMenuItemDef = (params: GetContextMenuItemsParams<ManifestStopEnhanced>): MenuItemDef => {
  return getMarkJobMenuItemDef(
    params,
    (jobStopId, disabled) => {
      return {
        name: GridMenuContextText.MarkStopComplete,
        action: () => (params.context as ManifestDetailsContext).handleMarkJobStopComplete(jobStopId),
        disabled: disabled
      };
    },
    (manifestStop: ManifestStop, jobStopId: number) => {
      return manifestStop.jobStopId === jobStopId && isCompleted(manifestStop);
    }
  );
};

const getMarkJobInCompleteMenuItemDef = (params: GetContextMenuItemsParams<ManifestStopEnhanced>): MenuItemDef => {
  return getMarkJobMenuItemDef(
    params,
    (jobStopId, disabled) => {
      return {
        name: GridMenuContextText.MarkStopIncomplete,
        action: () => (params.context as ManifestDetailsContext).handleMarkJobStopIncomplete(jobStopId),
        disabled: disabled
      };
    },
    (manifestStop: ManifestStop, jobStopId: number) => {
      return manifestStop.jobStopId === jobStopId && !isCompleted(manifestStop);
    }
  );
};

const getReassignJobMenuItemDef = (params: GetContextMenuItemsParams<ManifestStopEnhanced>): MenuItemDef => {
  const manifestDetailsContext = params.context as ManifestDetailsContext;
  if (manifestDetailsContext.selectedStops.length >= 1) {
    let disableReassign = false;
    const selectedJobIds = manifestDetailsContext.selectedStops.map((ms) => ms.job.jobId);

    selectedJobIds.forEach((id) => {
      if (disableReassign) return;
      const correspondedJobStops = manifestDetailsContext.manifest.stops?.filter((stop) => stop.job.jobId === id);
      if (correspondedJobStops?.every(isCompleted)) {
        disableReassign = true;
      }
    });

    let name = GridMenuContextText.ReassignJobs;
    if (isStopsAreSameJob(manifestDetailsContext.selectedStops)) {
      name = GridMenuContextText.ReassignJob;
      const correspondedJobStops = manifestDetailsContext.manifest.stops?.filter(
        (stop) => stop.job.jobId === manifestDetailsContext.selectedStops[0].job.jobId
      );
      if (isPartiallyCompletedJob({stops: correspondedJobStops} as any)) {
        name = GridMenuContextText.ReassignPartlyCompleteJob;
      }
    }

    return {
      name: name,
      action: () => manifestDetailsContext.handleReassignJob(),
      disabled: disableReassign
    };
  } else {
    let disableReassign = false;
    const jobId = params.node?.data?.job.jobId;
    const selectedJobStops = manifestDetailsContext.manifest.stops!.filter((ms) => {
      return ms.job.jobId === jobId;
    });
    disableReassign = selectedJobStops.every((stop) => isCompleted(stop));
    return {
      name: isPartiallyCompletedJob({stops: selectedJobStops} as any)
        ? GridMenuContextText.ReassignPartlyCompleteJob
        : GridMenuContextText.ReassignJob,
      action: () => manifestDetailsContext.handleReassignJob(params.node?.data as ManifestStopEnhanced),
      disabled: disableReassign
    };
  }
};

const getAdjustTimeJobMenuItemDef = (params: GetContextMenuItemsParams<ManifestStopEnhanced>): MenuItemDef => {
  const activeStopsOfSameJob =
    (params.context as ManifestDetailsContext).manifest.stops?.filter(
      (stop: ManifestStop) => stop.job.jobId === params.node?.data?.job.jobId && isActiveOrQueued(stop)
    ) ?? [];

  const isDisabled = activeStopsOfSameJob.every((stop) => !stop.lateDateTime && !stop.scheduledDateTime);

  return getMarkJobMenuItemDef(
    params,
    (_jobStopId, disabled) => {
      return {
        name: GridMenuContextText.AdjustJobStopTime,
        action: () => (params.context as ManifestDetailsContext).handleAdjustTimeJob(activeStopsOfSameJob),
        disabled: disabled
      };
    },
    () => {
      return isDisabled;
    }
  );
};

const getMarkJobMenuItemDef = (
  params: GetContextMenuItemsParams<ManifestStopEnhanced>,
  menuItemSupplier: (jobStopId: number, disabled: boolean) => MenuItemDef,
  disabledCheck: (manifestStop: ManifestStop, jobStopId: number) => boolean
): MenuItemDef => {
  const manifestDetailsContext = params.context as ManifestDetailsContext;
  const jobStopId = params.node?.data?.jobStopId;
  let markCompleteDisabled = false;
  if (jobStopId) {
    markCompleteDisabled = manifestDetailsContext.manifest.stops!.some((js) => disabledCheck(js, jobStopId!));
  }
  return menuItemSupplier(jobStopId!, markCompleteDisabled);
};

export const ManifestDetailsContextMenu = (
  params: GetContextMenuItemsParams<ManifestStopEnhanced, ManifestDetailsContext>
): (string | MenuItemDef)[] => {
  if (!params.node) return [];
  const result: (string | MenuItemDef)[] = [];
  if (params.context.selectedStops.length > 1 && !isStopsAreSameJob(params.context.selectedStops)) {
    result.push({name: GridMenuContextText.MultipleJobsSelected, disabled: true, cssClasses: ["menu-title"]});
  }
  result.push(getUnassignJobMenuItemDef(params));
  if (params.context) {
    if (params.context.selectedStops.length <= 1) {
      result.push(getMarkJobCompleteMenuItemDef(params));
      result.push(getMarkJobInCompleteMenuItemDef(params));
      result.push(getAdjustTimeJobMenuItemDef(params));
    }
    result.push(getReassignJobMenuItemDef(params));
  }

  return result;
};
