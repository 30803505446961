import JobTypeSelector, {StyledControlGroup} from "./JobTypeSelector";
import JobColorSelector from "./JobColorSelector";
import JobAttributesSelector from "./JobAttributesSelector";
import ComparisonTypeSelector from "./ComparisonTypeSelector";
import {useContext, useEffect} from "react";
import {isEmpty} from "lodash";
import TriggerSelector from "./TriggerSelector";
import {ComparisonTypes, IndicatorLogicType} from "./types/indicatorLogicType";
import SettingItemEditor, {
  EditorOptionItem,
  EditorOptionLabel,
  NoBackGroundSettingEditorButton,
  SettingEditorButton
} from "../components/SettingItemEditor";
import {SettingItemEditorMode} from "../components/types/SettingItemEditorMode";
import {ColorizedIndicatorContext, ColorizedIndicatorDispatchContext} from "./ColorizedIndicatorStateProvider";
import ManifestAttributeSelector from "./ManifestAttributeSelector";
import {JobTypes} from "./types/jobTypeSelectorValues";

const RequireFields: string[] = ["type", "color", "comparison", "trigger"];

type CreateColorizedIndicatorsProps = {
  selectedLogic: IndicatorLogicType;
  formMode: SettingItemEditorMode;
  isEditingLogicChanged: boolean;
  onDelete(logic: IndicatorLogicType): void;
  onSave(logic: IndicatorLogicType): void;
  onAddNew(): void;
  onCancel(): void;
};

const validateObject = (obj: any) => {
  if (!obj) {
    return false;
  }
  const keys = Object.keys(obj);
  const result = keys.filter((key) => {
    return isEmpty(obj[key]);
  });
  return result.length === 0;
};

const CreateColorizedIndicators = ({
  onSave,
  onCancel,
  onDelete,
  onAddNew,
  selectedLogic,
  formMode,
  isEditingLogicChanged
}: CreateColorizedIndicatorsProps) => {
  const colorizedIndicatorState = useContext(ColorizedIndicatorContext);
  const colorizedIndicatorDispatch = useContext(ColorizedIndicatorDispatchContext);

  //check if form is valid
  useEffect(() => {
    if (selectedLogic.manifestAttributes?.manifestStatus) {
      colorizedIndicatorDispatch({
        type: "SET_IS_FORM_VALID",
        payload: Boolean(validateObject(selectedLogic?.type) && selectedLogic?.color)
      });
    } else {
      colorizedIndicatorDispatch({
        type: "SET_IS_FORM_VALID",
        payload: Boolean(validateObject(selectedLogic?.type) && selectedLogic?.color && selectedLogic?.comparison)
      });
    }
  }, [colorizedIndicatorDispatch, selectedLogic]);
  const isOptionRequired = (fieldName: string) => {
    return RequireFields.includes(fieldName);
  };

  const handleCreateLogic = () => {
    if (selectedLogic) {
      onSave(selectedLogic);
    }
  };

  const handleDeleteLogic = () => {
    if (selectedLogic) {
      onDelete(selectedLogic);
    }
  };

  const editorFooterButtons = () => {
    const retVal = [];
    if (formMode === SettingItemEditorMode.Edit) {
      retVal.push(
        <SettingEditorButton intent="danger" onClick={handleDeleteLogic} data-testid="color-logic-delete">
          Delete
        </SettingEditorButton>
      );
    }
    retVal.push(
      <NoBackGroundSettingEditorButton
        disabled={formMode === SettingItemEditorMode.Disable}
        onClick={onCancel}
        data-testid="create-color-logic-cancel"
        key="cancel"
      >
        Cancel
      </NoBackGroundSettingEditorButton>,
      <SettingEditorButton
        disabled={
          formMode === SettingItemEditorMode.Disable ||
          !colorizedIndicatorState.isFormValid ||
          (formMode === SettingItemEditorMode.Edit && !isEditingLogicChanged)
        }
        onClick={handleCreateLogic}
        data-testid="color-logic-save"
        key="save"
      >
        {formMode !== SettingItemEditorMode.Edit ? "Create" : "Save Changes"}
      </SettingEditorButton>
    );
    return retVal;
  };

  return (
    <SettingItemEditor
      title={"Colorized Indicator Logic"}
      testId={"create-service-indicator"}
      createLabel={"Add New Logic"}
      editorMode={formMode}
      onCreateClick={onAddNew}
      footerButtons={editorFooterButtons()}
    >
      <EditorOptionItem key="type">
        <EditorOptionLabel fieldName="type" isRequired={isOptionRequired}>
          1. Select Type
        </EditorOptionLabel>
        <JobTypeSelector />
      </EditorOptionItem>
      <EditorOptionItem key="color">
        <EditorOptionLabel fieldName="color" isRequired={isOptionRequired}>
          2. Select Color
        </EditorOptionLabel>
        <StyledControlGroup>
          <JobColorSelector />
        </StyledControlGroup>
      </EditorOptionItem>
      <EditorOptionItem isMultiOptions key="jobAttributes">
        <EditorOptionLabel fieldName="jobAttributes" isRequired={isOptionRequired}>
          3. Specify Job Attributes
        </EditorOptionLabel>
        <JobAttributesSelector />
      </EditorOptionItem>
      {
        <>
          <EditorOptionItem key="orderPriority" isHidden={selectedLogic?.type?.jobTypeValue !== JobTypes.AssignedStops}>
            <EditorOptionLabel fieldName="manifestAttribute" isRequired={isOptionRequired}>
              4. Manifest Attribute
            </EditorOptionLabel>
            <StyledControlGroup>
              <ManifestAttributeSelector />
            </StyledControlGroup>
          </EditorOptionItem>
          <EditorOptionItem isMultiOptions key="comparison">
            <EditorOptionLabel
              fieldName="comparison"
              isRequired={selectedLogic.manifestAttributes?.manifestStatus ? () => false : isOptionRequired}
            >
              {selectedLogic?.type?.jobTypeValue !== JobTypes.AssignedStops ? "4" : "5"}. Specify Comparison Type
            </EditorOptionLabel>
            <ComparisonTypeSelector />
          </EditorOptionItem>
          <EditorOptionItem
            key="trigger"
            isMultiOptions
            isHidden={!(selectedLogic?.comparison && selectedLogic?.comparison !== ComparisonTypes.Continuous)}
          >
            <EditorOptionLabel fieldName="trigger" isRequired={isOptionRequired}>
              {selectedLogic?.type?.jobTypeValue !== JobTypes.AssignedStops ? "5" : "6"}. Specify When to Trigger
            </EditorOptionLabel>
            <TriggerSelector />
          </EditorOptionItem>
        </>
      }
    </SettingItemEditor>
  );
};

export default CreateColorizedIndicators;
