import {ControlGroup} from "@blueprintjs/core";
import styled from "@emotion/styled";
import {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {
  CustomerFilter,
  CustomerSortableField,
  ModelVehicleTypeFilterInput,
  SearchableSortDirection,
  SearchableVehicleTypeSortableFields,
  ServiceFilter,
  ServiceSortableField,
  SiteFilterInput,
  SiteSortableField,
  useSearchCustomersQuery,
  useSearchServicesQuery,
  useSearchSitesQuery,
  useSearchVehicleTypesQuery
} from "../../../generated/graphql";

import FilterableMultiSelect from "../../common/FilterableMultiSelect";
import {getArrayFromField, getOptionsFromArrayOfObjects} from "../../../utils/General";
import {OptionType} from "../../common/types/optionType";
import {JobAttributes} from "./types/jobAttributes";
import {andSearchWithWildcards} from "./RuleGenerator.service";
import {ColorizedIndicatorContext, ColorizedIndicatorDispatchContext} from "./ColorizedIndicatorStateProvider";
import PrioritySelector from "./PrioritySelector";
import {JobTypes, StopStatus} from "./types/jobTypeSelectorValues";
import IndicatorSelect, {IndicatorListItem} from "../../common/IndicatorSelect";
import {ItemRenderer} from "@blueprintjs/select";
import {renderSelectedTextStatusItem} from "./stopStatus.service";

const StyledConrolGroup = styled(ControlGroup)`
  width: fit-content;
  display: inline-flex;
  gap: 12px;
`;
const JobAttributesSelector = () => {
  const colorizedIndicatorState = useContext(ColorizedIndicatorContext);
  const colorizedIndicatorDispatch = useContext(ColorizedIndicatorDispatchContext);

  const [vehicleTypes, setVehicleTypes] = useState<OptionType[]>([]);
  const [services, setServices] = useState<OptionType[]>([]);
  const [customers, setCustomers] = useState<OptionType[]>([]);
  const [sites, setSites] = useState<OptionType[]>([]);

  const [vehicleTypeQuery, setVehicleTypeQuery] = useState<string>("");
  const [vehicleTypeFilter, setVehicleTypeFilter] = useState<ModelVehicleTypeFilterInput | undefined>(undefined);

  const [serviceTypeQuery, setServiceTypeQuery] = useState<string>("");
  const [serviceTypeFilter, setServiceTypeFilter] = useState<ServiceFilter | undefined>(undefined);

  const [customerQuery, setCustomerQuery] = useState<string>("");
  const [customerFilter, setCustomerFilter] = useState<CustomerFilter | undefined>(undefined);
  const [siteQuery, setSiteQuery] = useState<string>("");
  const [siteFilter, setSiteFilter] = useState<SiteFilterInput | undefined>(undefined);

  const hasStopStatusOptions = useMemo(() => {
    return colorizedIndicatorState.selectedLogic?.type?.jobTypeValue === JobTypes.AssignedStops;
  }, [colorizedIndicatorState.selectedLogic]);

  const selectedJobAttributes = useMemo(() => {
    return colorizedIndicatorState.selectedLogic?.jobAttributes as JobAttributes;
  }, [colorizedIndicatorState.selectedLogic]);

  const renderStopStatus: ItemRenderer<[StopStatus, string]> = (item, {handleClick}) => {
    return (
      <IndicatorListItem
        key={item[0]}
        onClick={handleClick}
        isSelected={selectedJobAttributes?.stopStatus?.includes(item[1]) ?? false}
      >
        {item[0]}
      </IndicatorListItem>
    );
  };

  const vehicleTypesResults = useSearchVehicleTypesQuery({
    variables: {
      limit: 200,
      filter: vehicleTypeFilter,
      sort: {
        field: SearchableVehicleTypeSortableFields.Description,
        direction: SearchableSortDirection.Asc
      }
    }
  });

  const servicesResults = useSearchServicesQuery({
    variables: {
      ...{
        limit: 200,
        sort: {
          field: ServiceSortableField.Description,
          direction: SearchableSortDirection.Asc
        },
        filter: {
          ...serviceTypeFilter,
          status: {
            match: "A"
          }
        }
      }
    }
  });

  const customersResults = useSearchCustomersQuery({
    variables: {
      limit: 200,
      filter: customerFilter,
      sort: {
        field: CustomerSortableField.Name,
        direction: SearchableSortDirection.Asc
      }
    }
  });

  const sitesResults = useSearchSitesQuery({
    variables: {
      limit: 200,
      filter: {
        ...siteFilter,
        status: {
          eq: "A"
        }
      },
      sort: {
        field: SiteSortableField.Code,
        direction: SearchableSortDirection.Asc
      }
    }
  });

  useEffect(() => {
    if (vehicleTypeQuery?.trim()) {
      const tokens = vehicleTypeQuery.trim().split(" ");
      setVehicleTypeFilter(andSearchWithWildcards("description", tokens));
    } else {
      setVehicleTypeFilter({});
    }
  }, [vehicleTypeQuery]);

  useEffect(() => {
    if (serviceTypeQuery?.trim()) {
      const tokens = serviceTypeQuery.trim().split(" ");
      setServiceTypeFilter(andSearchWithWildcards("description", tokens));
    } else {
      setServiceTypeFilter(undefined);
    }
  }, [serviceTypeQuery]);

  useEffect(() => {
    if (customerQuery?.trim()) {
      const tokens = customerQuery.trim().split(" ");
      setCustomerFilter(andSearchWithWildcards("name", tokens));
    } else {
      setCustomerFilter({});
    }
  }, [customerQuery]);

  useEffect(() => {
    if (siteQuery?.trim()) {
      const tokens = siteQuery.trim().split(" ");
      setSiteFilter(andSearchWithWildcards("code", tokens));
    } else {
      setSiteFilter({});
    }
  }, [siteQuery]);

  useEffect(() => {
    let isComponentMounted = true;

    if (isComponentMounted) {
      if (vehicleTypesResults.error) {
        console.error(
          "JobAttributes selected cannot load vehicle types.",
          vehicleTypesResults.error,
          vehicleTypesResults.error.graphQLErrors
        );
      }

      if (!vehicleTypesResults.loading && vehicleTypesResults.data?.searchVehicleTypes) {
        const vehicleTypeOptions = getOptionsFromArrayOfObjects(
          getArrayFromField(vehicleTypesResults.data.searchVehicleTypes.items),
          "vehicleTypeId",
          "description"
        );
        setVehicleTypes(vehicleTypeOptions);
      }
    }
    return () => {
      isComponentMounted = false;
    };
  }, [vehicleTypesResults.data, vehicleTypesResults.error, vehicleTypesResults.loading]);

  useEffect(() => {
    let isComponentMounted = true;

    if (isComponentMounted) {
      if (servicesResults.error) {
        console.error(
          "JobAttributes selected cannot load services.",
          servicesResults.error,
          servicesResults.error.graphQLErrors
        );
      }

      if (!servicesResults.loading && servicesResults.data?.searchServices) {
        const serviceOptions = getOptionsFromArrayOfObjects(
          getArrayFromField(servicesResults.data.searchServices.items),
          "serviceId",
          "service"
        );
        setServices(serviceOptions);
      }
    }
    return () => {
      isComponentMounted = false;
    };
  }, [servicesResults.data, servicesResults.error, servicesResults.loading]);

  useEffect(() => {
    let isComponentMounted = true;

    if (isComponentMounted) {
      if (customersResults.error) {
        console.error(
          "JobAttributes selected cannot load customers.",
          customersResults.error,
          customersResults.error.graphQLErrors
        );
      }

      if (!customersResults.loading && customersResults.data?.searchCustomers) {
        const customerOptions = getOptionsFromArrayOfObjects(
          getArrayFromField(customersResults.data.searchCustomers.items),
          "customerId",
          "name"
        );
        setCustomers(customerOptions);
      }
    }
    return () => {
      isComponentMounted = false;
    };
  }, [customersResults.data, customersResults.error, customersResults.loading]);

  useEffect(() => {
    let isComponentMounted = true;

    if (isComponentMounted) {
      if (sitesResults.error) {
        console.error(
          "JobAttributes selected cannot load customers.",
          sitesResults.error,
          sitesResults.error.graphQLErrors
        );
      }

      if (!sitesResults.loading && sitesResults.data?.searchSites) {
        const siteOptions = getOptionsFromArrayOfObjects(
          getArrayFromField(sitesResults.data.searchSites.items),
          "siteId",
          "code"
        );
        setSites(siteOptions);
      }
    }
    return () => {
      isComponentMounted = false;
    };
  }, [sitesResults.data, sitesResults.error, sitesResults.loading]);

  const onServiceSelect = (item: OptionType) => {
    const existingItemIndex = selectedJobAttributes?.service.findIndex((s) => s.key === item.key);
    let newSelectedServices = [...selectedJobAttributes.service];
    if (existingItemIndex === -1) {
      newSelectedServices.push(item);
    } else {
      newSelectedServices = selectedJobAttributes?.service.filter((s) => s.key !== item.key);
    }

    colorizedIndicatorDispatch({type: "SET_JOB_ATTRIBUTES_SERVICE", payload: newSelectedServices});
    colorizedIndicatorDispatch({type: "SET_ENGINE_RULES"});
  };

  const onCustomerSelect = (item: OptionType) => {
    const customers = selectedJobAttributes?.customer ?? [];
    const existingItemIndex = customers.findIndex((c) => c.key === item.key);
    let newSelectedCustomers = [...customers];
    if (existingItemIndex === -1) {
      newSelectedCustomers.push(item);
    } else {
      newSelectedCustomers = customers.filter((c) => c.key !== item.key);
    }

    colorizedIndicatorDispatch({type: "SET_JOB_ATTRIBUTES_CUSTOMER", payload: newSelectedCustomers});
    colorizedIndicatorDispatch({type: "SET_ENGINE_RULES"});
  };

  const onVehicleTypeSelect = (item: OptionType) => {
    const vehicleTypes = selectedJobAttributes?.vehicleType ?? [];
    const existingItemIndex = vehicleTypes.findIndex((c) => c.key === item.key);
    let newSelectedVehicleTypes = [...vehicleTypes];
    if (existingItemIndex === -1) {
      newSelectedVehicleTypes.push(item);
    } else {
      newSelectedVehicleTypes = vehicleTypes.filter((c) => c.key !== item.key);
    }

    colorizedIndicatorDispatch({type: "SET_JOB_ATTRIBUTES_VEHICLETYPE", payload: newSelectedVehicleTypes});
    colorizedIndicatorDispatch({type: "SET_ENGINE_RULES"});
  };

  const onStopStatusSelectorChange = useCallback(
    (selectedItem: StopStatus) => {
      colorizedIndicatorDispatch({
        type: "SET_JOB_ATTRIBUTES_STOPSTATUS",
        payload: [selectedItem.at(1) as string]
      });
      colorizedIndicatorDispatch({type: "SET_ENGINE_RULES"});
    },
    [colorizedIndicatorDispatch]
  );
  const onSiteSelect = (item: OptionType) => {
    const sites = selectedJobAttributes?.site ?? [];
    const existingItemIndex = sites.findIndex((c) => c.key === item.key);
    let newSelectedSites = [...sites];
    if (existingItemIndex === -1) {
      newSelectedSites.push(item);
    } else {
      newSelectedSites = sites.filter((c) => c.key !== item.key);
    }

    colorizedIndicatorDispatch({type: "SET_JOB_ATTRIBUTES_SITE", payload: newSelectedSites});
    colorizedIndicatorDispatch({type: "SET_ENGINE_RULES"});
  };

  const onClearJobAttributes = (fieldName: string) => {
    colorizedIndicatorDispatch({type: `SET_JOB_ATTRIBUTES_${fieldName.toUpperCase()}` as any, payload: []});
    colorizedIndicatorDispatch({type: "SET_ENGINE_RULES"});
  };

  return (
    <StyledConrolGroup vertical={true}>
      <StyledConrolGroup>
        <FilterableMultiSelect
          options={services}
          testIdPrefix={"service"}
          onOptionSelect={onServiceSelect}
          selectedOptions={selectedJobAttributes?.service ?? []}
          onClear={() => {
            onClearJobAttributes("service");
          }}
          onQueryChange={setServiceTypeQuery}
          placeholder="Search services"
          text={`Service Types(s)${
            selectedJobAttributes?.service?.length > 0 ? ` (${selectedJobAttributes?.service.length})` : ""
          }`}
          onLoadMore={() => {
            servicesResults.refetch({
              ...{
                limit: services.length + 20
              },
              ...(serviceTypeFilter && {filter: serviceTypeFilter})
            });
          }}
        />
        <FilterableMultiSelect
          options={customers}
          testIdPrefix={"customer"}
          onOptionSelect={onCustomerSelect}
          selectedOptions={selectedJobAttributes?.customer ?? []}
          onClear={() => {
            onClearJobAttributes("customer");
          }}
          onQueryChange={setCustomerQuery}
          placeholder="Search customers"
          text={`Customer(s)${
            selectedJobAttributes?.customer?.length > 0 ? ` (${selectedJobAttributes?.customer.length})` : ""
          }`}
          onLoadMore={() => {
            customersResults.refetch({
              limit: customers.length + 20
            });
          }}
        />
        <FilterableMultiSelect
          options={vehicleTypes}
          testIdPrefix={"vehicleType"}
          onOptionSelect={onVehicleTypeSelect}
          selectedOptions={selectedJobAttributes?.vehicleType ?? []}
          onClear={() => {
            onClearJobAttributes("vehicleType");
          }}
          onQueryChange={setVehicleTypeQuery}
          placeholder="Search vehicle types"
          text={`Vehicle Types(s)${
            selectedJobAttributes?.vehicleType?.length > 0 ? ` (${selectedJobAttributes?.vehicleType.length})` : ""
          }`}
          onLoadMore={() => {
            vehicleTypesResults.refetch({
              limit: vehicleTypes.length + 20
            });
          }}
        />
      </StyledConrolGroup>
      <StyledConrolGroup>
        <PrioritySelector />
        <FilterableMultiSelect
          options={sites}
          testIdPrefix={"site"}
          onOptionSelect={onSiteSelect}
          selectedOptions={selectedJobAttributes?.site ?? []}
          onClear={() => {
            onClearJobAttributes("site");
          }}
          onQueryChange={setSiteQuery}
          placeholder="Search sites"
          text={`Site(s)${selectedJobAttributes?.site?.length > 0 ? ` (${selectedJobAttributes?.site.length})` : ""}`}
          onLoadMore={() => {
            sitesResults.refetch({
              limit: sites.length + 20
            });
          }}
        />
        {hasStopStatusOptions && (
          <IndicatorSelect
            name="stop-status"
            items={Object.entries(StopStatus) as [StopStatus, string][]}
            itemRenderer={renderStopStatus}
            onSelectedItem={onStopStatusSelectorChange}
            placeholderText="Stop Status"
            selectedItemText={renderSelectedTextStatusItem(selectedJobAttributes?.stopStatus?.at(0))}
            onClear={() => {
              onClearJobAttributes("stopStatus");
            }}
          />
        )}
      </StyledConrolGroup>
    </StyledConrolGroup>
  );
};

export default JobAttributesSelector;
