import {useState, useEffect, useCallback, useContext} from "react";
import styled from "@emotion/styled";
import {getEllipsizeText, getKeysFromArrayOfObjects, isSameDate, shouldEllipsize} from "../../../utils/General";
import {Button, Icon, Tag, Text} from "@blueprintjs/core";
import CreateManifestDatePicker, {formatDate} from "./CreateManifestDatePicker";
import {add} from "date-fns";
import {
  Driver,
  SearchableDispatchGroupSortableFields,
  SearchableSortDirection,
  VehicleType,
  useSearchDispatchGroupsQuery
} from "../../../generated/graphql";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import VehicleIconResolver from "../../vehicles/VehicleIconResolver";
import {Tooltip2} from "@blueprintjs/popover2";
import {ManifestModalDialog} from "../ManifestModalDialog";
import {Constants, EmptyValueStrings, TenantPreferences} from "../../common/Constants";
import DispatchGroupsSeletor from "../DispatchGroupsSeletor";
import InfiniteScroll from "react-infinite-scroll-component";
import {extractSimplePref, PreferenceContext} from "../../../providers/PreferenceProvider";
import {ManifestStatusTypes} from "../../settings/ColorizedIndicators/types/manifestAttributes";
import ManifestStatusSeletor from "../ManifestStatusSeletor";
import {InformationIcon} from "../../settings/CompanySettings";

export type DriverItem = Driver & {manifestDriverStatus?: ManifestStatusTypes};

interface MultiSelectProps {
  vehicleTypes: VehicleType[];
  options: Driver[];
  selectedOptions: DriverItem[];
  onOptionSelect: (item: DriverItem) => void;
  onCreateManifest: (date: Date) => void;
  onSearchDriver: (query: string) => void;
  onClear: () => void;
  onClose: () => void;
  onLoadMore: () => void;
}

export type TGroupOption = {
  key: number;
  value: string;
};

export type TChangedDispactGroupState = TGroupOption & {
  driverId: number;
};

type OptionItemProps = {
  index: number;
  item: Driver;
  vehicleDescription?: string;
  isHighlight: boolean;
  changedDispactGroupState: TChangedDispactGroupState | undefined;
  onSelectStatus: (driverId: number, status: ManifestStatusTypes) => void;
  selectedStatus: ManifestStatusTypes;
  onClick: (item: DriverItem) => void;
  onEditClick: (item: Driver) => void;
  isHide: boolean;
};

type TSelectedItemProps = {
  item: DriverItem;
  handleClick: (item: DriverItem) => void;
};

const today = new Date();
const tomorrow = add(today, {
  days: 1
});

const getSelectedDatePrefixText = (selectedDate: Date) => {
  if (isSameDate(today, selectedDate)) return "Today, ";
  if (isSameDate(tomorrow, selectedDate)) return "Tomorrow, ";
  return "";
};

const CreateManifestModal = ({
  vehicleTypes,
  options,
  selectedOptions,
  onOptionSelect,
  onCreateManifest,
  onSearchDriver,
  onClear,
  onClose,
  onLoadMore
}: MultiSelectProps) => {
  const [dispatchGroupOptions, setDispatchGroupOptions] = useState<TGroupOption[]>([]);
  const [isSelectingDate, setIsSelectingDate] = useState(true);
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined);
  const [selectedGroup, setSelectedGroup] = useState<TGroupOption | undefined>(undefined);
  const [editingDriver, setEditingDriver] = useState<Driver | undefined>(undefined);
  const [changedDispactGroupState, setChangedDispactGroupState] = useState<TChangedDispactGroupState[]>([]);
  const [changedStatusState, setChangedStatusState] = useState<Map<number, ManifestStatusTypes>>();
  const {tenantPreferences} = useContext(PreferenceContext);
  const defaultManifestStatus = extractSimplePref(
    tenantPreferences,
    TenantPreferences.defaultManifestStatus,
    ManifestStatusTypes.Active
  ).value as ManifestStatusTypes;

  const [query, setQuery] = useState<string>("");

  useSearchDispatchGroupsQuery({
    variables: {
      filter: {
        dispatchType: {
          eq: "D"
        }
      },
      sort: {
        field: SearchableDispatchGroupSortableFields.DispatchGroup,
        direction: SearchableSortDirection.Asc
      },
      limit: Constants.DISPATCH_GROUP_LIMIT
    },
    onCompleted(data) {
      if (data.searchDispatchGroups?.items) {
        const groupOptions: TGroupOption[] = data.searchDispatchGroups.items.map((item) => {
          return {key: item?.dispatchGroupId as number, value: item?.dispatchGroup ?? EmptyValueStrings.unknown};
        });
        setDispatchGroupOptions(groupOptions);
      }
    }
  });

  const onChangeFilter = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setQuery(query);
  }, []);

  const onSelectDriverHandler = useCallback(
    (item: DriverItem) => {
      onOptionSelect(item);
      setEditingDriver(undefined);
      setQuery("");
    },
    [onOptionSelect]
  );

  const onEditClickHandler = useCallback(
    (driver: Driver) => {
      if (editingDriver?.driverId !== driver.driverId) {
        setEditingDriver(driver);
      } else {
        setEditingDriver(undefined);
      }
      setSelectedGroup(undefined);
    },
    [editingDriver]
  );

  const onChangeStatusHandler = useCallback(
    (driverId: number, status: ManifestStatusTypes) => {
      const newState = new Map(changedStatusState);
      newState.set(driverId, status);
      setChangedStatusState(newState);
    },
    [changedStatusState]
  );

  const renderOptionList = useCallback(
    (options: Driver[]) => {
      if (
        options.filter((option) => !getKeysFromArrayOfObjects(selectedOptions, "driverId").includes(option.driverId))
          .length === 0
      ) {
        return <div style={{textAlign: "center", fontSize: "14px", fontStyle: "italic"}}>No driver found!</div>;
      }
      return options.map((option, index) => {
        const vehicleDescription =
          vehicleTypes?.find((vehicleType) => vehicleType.vehicleTypeId === option.vehicleTypeId)?.description || "";
        return (
          <OptionItem
            isHide={getKeysFromArrayOfObjects(selectedOptions, "driverId").includes(option.driverId)}
            index={index}
            key={option.driverId}
            item={option}
            isHighlight={editingDriver?.driverId === option.driverId}
            vehicleDescription={vehicleDescription}
            onClick={onSelectDriverHandler}
            onEditClick={onEditClickHandler}
            changedDispactGroupState={changedDispactGroupState.find((item) => item.driverId === option.driverId)}
            onSelectStatus={onChangeStatusHandler}
            selectedStatus={changedStatusState?.get(option.driverId!) ?? defaultManifestStatus}
          />
        );
      });
    },
    [
      changedDispactGroupState,
      changedStatusState,
      defaultManifestStatus,
      editingDriver?.driverId,
      onChangeStatusHandler,
      onEditClickHandler,
      onSelectDriverHandler,
      selectedOptions,
      vehicleTypes
    ]
  );

  const renderTagList = useCallback(
    (options: DriverItem[]) => {
      return options.map((option) => {
        return <ItemTag key={option.driverId} item={option} handleClick={onOptionSelect} />;
      });
    },
    [onOptionSelect]
  );

  const changeDateHanlder = useCallback((date: Date | undefined, isUserChange: boolean) => {
    if (!date) {
      setIsSelectingDate(false);
      return;
    }
    if (isUserChange) {
      setSelectedDate(date);
      setIsSelectingDate(false);
    }
  }, []);

  const createManifestHandler = useCallback(() => {
    if (selectedDate) {
      onCreateManifest(selectedDate);
    }
  }, [onCreateManifest, selectedDate]);

  const clearHandler = useCallback(() => {
    setQuery("");
    onClear();
  }, [onClear]);

  //Change dispatch group handlers
  const onSelectGroupHandler = useCallback((group: TGroupOption) => {
    setSelectedGroup(group);
  }, []);

  const onCancelEditGroupHandler = useCallback(() => {
    setSelectedGroup(undefined);
    setEditingDriver(undefined);
  }, []);

  const onConfirmEditGroupHandler = useCallback(() => {
    if (editingDriver?.dispatchGroupId?.toString() !== selectedGroup?.key.toString()) {
      setChangedDispactGroupState((prev) => [
        ...prev.filter((item) => item.driverId !== editingDriver?.driverId),
        {...selectedGroup!, driverId: editingDriver?.driverId as number}
      ]);
    } else {
      setChangedDispactGroupState((prev) => prev.filter((item) => item.driverId !== editingDriver?.driverId));
    }
    onCancelEditGroupHandler();
  }, [editingDriver?.dispatchGroupId, editingDriver?.driverId, onCancelEditGroupHandler, selectedGroup]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (query.length >= 3 || query.length === 0) {
        onSearchDriver(query);
        if (editingDriver) onCancelEditGroupHandler();
      }
    }, 300);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return (
    <ManifestModalDialog id="create-manifest" onClose={onClose}>
      {isSelectingDate ? (
        <CreateManifestDatePicker onChangeDate={changeDateHanlder} selectedDate={selectedDate} />
      ) : (
        <Container>
          <Header>
            <Title>Select driver(s) for</Title>
            {selectedDate && (
              <SelectedDateText>
                {getSelectedDatePrefixText(selectedDate)}
                {selectedDate.toLocaleString("US", formatDate)}
              </SelectedDateText>
            )}
          </Header>
          <Body>
            <SearchContainer>
              <Search>
                <StyledIcon icon="search" />
                <StyledInput type="text" placeholder="Search by Name or ID" value={query} onChange={onChangeFilter} />
                {query && <ClearInputButton icon="cross" minimal onClick={() => setQuery("")} />}
              </Search>
              <StyledCalendarButton
                data-testid="calendar-button"
                onClick={() => setIsSelectingDate(true)}
                rightIcon={<CalendarIcon />}
              />
            </SearchContainer>
            <SelectedItemsContainer>{renderTagList(selectedOptions)}</SelectedItemsContainer>
            <InfoContainer>
              <Tooltip2
                popoverClassName="content-tooltip"
                content={
                  "The default status of the manifest is defined in the Dispatch company settings by your admin(s). You can adjust the status of the manifest in this flow or after the manifest is created."
                }
                placement="top"
                intent="none"
              >
                <InformationIcon scale={0.6} />
              </Tooltip2>
            </InfoContainer>
            <ItemsContainer
              hasSelectedOptions={selectedOptions.length > 0}
              id={"option-infinity-scroll-list"}
              data-testid="drivers-option-container"
            >
              <InfiniteScroll
                next={onLoadMore}
                dataLength={options.length}
                hasMore={true}
                scrollableTarget={"option-infinity-scroll-list"}
                loader={""}
              >
                <OptionList>{renderOptionList(options)}</OptionList>
              </InfiniteScroll>
            </ItemsContainer>
          </Body>
          <Footer>
            <ClearButton
              data-testid="clear-selected-driver-button"
              onClick={clearHandler}
              disabled={selectedOptions.length === 0}
            >
              Clear{selectedOptions.length ? <span style={{color: "red"}}> ({selectedOptions.length})</span> : ""}
            </ClearButton>
            <CreateButton onClick={createManifestHandler} disabled={selectedOptions.length === 0}>
              Create
            </CreateButton>
          </Footer>
          {editingDriver && (
            <GroupSelectContainer>
              <DispatchGroupsSeletor
                initGroup={{
                  key: Number(editingDriver.dispatchGroupId),
                  value: editingDriver.dispatchGroup?.dispatchGroup ?? EmptyValueStrings.unknown
                }}
                options={dispatchGroupOptions}
                selectedGroup={selectedGroup}
                onSelectGroup={onSelectGroupHandler}
                onConfirm={onConfirmEditGroupHandler}
                onCancel={onCancelEditGroupHandler}
                changedDispactGroupState={changedDispactGroupState.find(
                  (item) => item.driverId === editingDriver.driverId
                )}
              />
            </GroupSelectContainer>
          )}
        </Container>
      )}
    </ManifestModalDialog>
  );
};

export default CreateManifestModal;

const ItemTag = ({item, handleClick}: TSelectedItemProps) => {
  return (
    <StyledTag
      data-testid="selected-item"
      style={{margin: "1.5px"}}
      key={item.driverId}
      rightIcon={
        <Icon
          data-testid="deselect-item-button"
          style={{cursor: "pointer"}}
          icon="cross"
          size={16}
          onClick={() => handleClick(item)}
        />
      }
    >
      <Text ellipsize>{item.name ? item.name : EmptyValueStrings.unknown}</Text>
    </StyledTag>
  );
};

const OptionItem = ({
  item,
  index,
  vehicleDescription,
  isHighlight,
  changedDispactGroupState,
  onSelectStatus,
  selectedStatus,
  onClick,
  onEditClick,
  isHide
}: OptionItemProps) => {
  const {tenantPreferences} = useContext(PreferenceContext);

  const onSelect = useCallback(
    (value: ManifestStatusTypes) => {
      onSelectStatus(item.driverId!, value);
    },
    [item.driverId, onSelectStatus]
  );

  if (isHide) return null;

  return (
    <Option
      data-testid="driver-item"
      index={index}
      key={item.driverId}
      onClick={() =>
        onClick({
          ...item,
          dispatchGroupId: changedDispactGroupState?.key ?? item.dispatchGroupId,
          manifestDriverStatus: selectedStatus
        })
      }
      isHighlight={isHighlight}
    >
      <div></div>
      <StyledButton
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <ManifestStatusSeletor selectedStatus={selectedStatus} onSelectStatus={onSelect} minimal />
      </StyledButton>
      <FontAwesomeIcon
        data-testid={"vehicle-icon"}
        size={"lg"}
        color={"#797979"}
        icon={VehicleIconResolver(vehicleDescription, tenantPreferences)}
        title={vehicleDescription as string}
        style={{margin: "auto"}}
      />
      <Tooltip2
        popoverClassName="white-popover"
        content={shouldEllipsize(item.name, 19) ? (item.name as string) : ""}
        placement="bottom-start"
      >
        <BlackText>{getEllipsizeText(item.name, 19)}</BlackText>
      </Tooltip2>
      <Tooltip2
        popoverClassName="white-popover"
        content={shouldEllipsize(item.driverCode, 10) ? (item.driverCode as string) : ""}
        placement="bottom-start"
      >
        <GrayText>{getEllipsizeText(item.driverCode, 10)}</GrayText>
      </Tooltip2>
      <DispacthGroupDetail>
        <Tooltip2
          popoverClassName="white-popover"
          content={
            shouldEllipsize(changedDispactGroupState?.value ?? item.dispatchGroup?.dispatchGroup, 10)
              ? changedDispactGroupState?.value ?? item.dispatchGroup?.dispatchGroup ?? EmptyValueStrings.unknown
              : ""
          }
          placement="bottom-start"
        >
          <BlackText ellipsize>
            {getEllipsizeText(changedDispactGroupState?.value ?? item.dispatchGroup?.dispatchGroup, 10)}
          </BlackText>
        </Tooltip2>
        {changedDispactGroupState && (
          <Tooltip2
            popoverClassName="white-popover"
            position="top"
            content={`Changed from '${item.dispatchGroup?.dispatchGroup ?? EmptyValueStrings.unknown}'`}
          >
            <Icon icon={<SwapIcon />} />
          </Tooltip2>
        )}
      </DispacthGroupDetail>
      <Tooltip2 minimal popoverClassName="white-popover" placement="bottom-end" content={"Change driver’s group"}>
        <EditButton
          data-testid="edit-button"
          minimal
          icon={<Icon icon={"more"} style={{rotate: "90deg"}} />}
          onClick={(e) => {
            e.stopPropagation();
            onEditClick(item);
          }}
        />
      </Tooltip2>
    </Option>
  );
};

export const ClearInputButton = styled(Button)`
  min-width: 25px;
  min-height: 25px;
  margin-right: 2px;
`;

const StyledButton = styled(Button)`
  background: none !important;
  box-shadow: none !important;
`;

const DispacthGroupDetail = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 6px;
  overflow: hidden;

  .bp4-popover2-target {
    display: flex;
    align-items: center;
    width: fit-content;
  }
`;

const SearchContainer = styled.div`
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: 10px;
`;

const SelectedItemsContainer = styled.div`
  padding: 0 20px;
  max-height: 90px;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  margin-bottom: 8px;
`;

const ItemsContainer = styled.div<{hasSelectedOptions?: boolean}>`
  height: ${(props) => (props.hasSelectedOptions ? "calc(100% - 162px)" : "calc(100% - 62px)")};
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const Header = styled.div`
  gap: 4px;
  display: flex;
  padding: 10px 20px 0;
`;

const Title = styled.div`
  font-weight: 400;
  text-transform: uppercase;
`;

const SelectedDateText = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #797979;
`;

const Container = styled.div`
  position: relative;
  width: 512px;
  height: 70vh;
  display: grid;
  grid-template-rows: 38px 1fr 62px;
`;

const Body = styled.div`
  height: calc(70vh - 100px);
  display: flex;
  flex-direction: column;
`;

const Footer = styled.div`
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
`;

const InfoContainer = styled.div`
  height: 22px;
  margin-left: 18px;
`;

const GroupSelectContainer = styled.div`
  position: absolute;
  height: 70vh;
  width: 190px;
  top: 0;
  right: -190px;
  background: #fff;
  display: flex;
  overflow: hidden;
`;

const OptionList = styled.ul`
  list-style: none;
  text-overflow: ellipsis;
  padding: 0;
  margin: 0;
`;

const StyledTag = styled(Tag)`
  background-color: #8da4beb2;
  font-size: 12px;
  border-radius: 4px;
`;

const Option = styled.li<{index: number; isHighlight: boolean}>`
  box-sizing: border-box;
  background-color: ${(props) => (props.isHighlight ? "#0038ff33" : props.index % 2 === 0 ? "#ffffff" : "#FCFCFC")};
  height: 43px;
  color: #161616;
  font-size: 14px;
  border-bottom: 1px solid #eeeeee;
  border-top: ${(props) => (props.index === 0 ? "1px solid #eeeeee" : "")};
  display: grid;
  grid-template-columns: 15px 35px 55px 1.4fr 1fr 1.2fr 40px;
  align-items: center;
  gap: 4px;

  :hover {
    background-color: #0038ff33;
    cursor: pointer;
  }
`;

const BlackText = styled(Text)`
  width: 100%;
  display: flex;
  align-items: center;
`;

const GrayText = styled(Text)`
  width: 100%;
  display: flex;
  align-items: center;
  color: #797979;
`;

const EditButton = styled(Button)`
  width: 20px;
  height: 20px;
`;

const Search = styled.div`
  width: 100%;
  font-family: "Roboto", sans-serif;
  position: relative;
  border-radius: 4px;
  display: flex;
  height: 32px;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0px 1px 3px 0px #0000001a inset;
  border: 1px solid #c3c3c373;
`;

const StyledIcon = styled(Icon)`
  color: #a4a5a6;
  padding: 8px 8px 8px 12px;
`;

const StyledInput = styled.input`
  display: inline;
  width: 100%;
  line-height: 16px;
  border: none;
  outline: none;
`;

const ClearButton = styled.button`
  font-size: 16px;
  font-weight: 400;
  line-height: 14px;
  border: none;
  color: #14305a;
  background-color: transparent;
  cursor: pointer;
  padding: 0;

  span {
    color: #fa545e;
  }

  :disabled {
    color: #8da4be;
  }
`;

const CreateButton = styled(Button)`
  &.bp4-button:not([class*="bp4-intent-"]) {
    color: #ffffff;
    background-color: "#14305a";
    background: linear-gradient(0deg, rgba(15, 18, 23, 0.1), rgba(15, 18, 23, 0.1)),
      linear-gradient(0deg, #14305a, #14305a) !important;
    border: 1px solid #d9d9d9 !important;
    box-shadow: 0px 1px 2px 0px #0000000d !important;
  }

  &.bp4-button:not([class*="bp4-intent-"]):disabled {
    color: #fbfbfb;
    background-color: #d9d9d9;
    background: linear-gradient(0deg, rgba(15, 18, 23, 0.1), rgba(15, 18, 23, 0.1)),
      linear-gradient(0deg, #d9d9d9, #d9d9d9) !important;
    border: 1px solid #d9d9d9 !important;
    box-shadow: 0px 1px 2px 0px #0000000d !important;
  }
  font-size: 16px;
  font-weight: 400;
  border-radius: 4px;
  height: 32px;
  padding: 6px 14px;
  border: 1px;
  gap: 7px;
`;

const StyledCalendarButton = styled(Button)`
  &.bp4-button:not([class*="bp4-intent-"]) {
    color: #ffffff;
    background-color: #ffffff;
    border: 1px solid #d9d9d9 !important;
    box-shadow: 0px 1px 1px 0px #00000040 !important;
    border-radius: 4px;
  }
`;

const CalendarIcon = () => {
  return (
    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.86224 1.28544C4.86224 0.929185 4.55517 0.642578 4.17347 0.642578C3.79177 0.642578 3.48469 0.929185 3.48469 1.28544V2.35686H2.33673C1.32366 2.35686 0.5 3.12561 0.5 4.07115V4.49972V5.78544V12.6426C0.5 13.5881 1.32366 14.3569 2.33673 14.3569H11.5204C12.5335 14.3569 13.3571 13.5881 13.3571 12.6426V5.78544V4.49972V4.07115C13.3571 3.12561 12.5335 2.35686 11.5204 2.35686H10.3724V1.28544C10.3724 0.929185 10.0654 0.642578 9.68367 0.642578C9.30198 0.642578 8.9949 0.929185 8.9949 1.28544V2.35686H4.86224V1.28544ZM1.87755 5.78544H11.9796V12.6426C11.9796 12.8783 11.773 13.0711 11.5204 13.0711H2.33673C2.08418 13.0711 1.87755 12.8783 1.87755 12.6426V5.78544Z"
        fill="#797979"
      />
    </svg>
  );
};

export const SwapIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2813_23756)">
      <path
        d="M2.08828 4.74805C2.26875 4.23711 2.56172 3.75664 2.97422 3.34648C4.43906 1.88164 6.81328 1.88164 8.27813 3.34648L8.67891 3.74961H7.5C7.08516 3.74961 6.75 4.08477 6.75 4.49961C6.75 4.91445 7.08516 5.24961 7.5 5.24961H10.4883H10.4977C10.9125 5.24961 11.2477 4.91445 11.2477 4.49961V1.49961C11.2477 1.08477 10.9125 0.749609 10.4977 0.749609C10.0828 0.749609 9.74766 1.08477 9.74766 1.49961V2.69961L9.3375 2.28711C7.28672 0.236328 3.96328 0.236328 1.9125 2.28711C1.34062 2.85898 0.928125 3.53164 0.675 4.25117C0.536719 4.64258 0.742969 5.06914 1.13203 5.20742C1.52109 5.3457 1.95 5.13945 2.08828 4.75039V4.74805ZM0.539062 6.78008C0.421875 6.81523 0.309375 6.87852 0.217969 6.97227C0.124219 7.06602 0.0609375 7.17852 0.028125 7.30039C0.0210938 7.32852 0.0140625 7.35898 0.009375 7.38945C0.00234375 7.4293 0 7.46914 0 7.50898V10.4996C0 10.9145 0.335156 11.2496 0.75 11.2496C1.16484 11.2496 1.5 10.9145 1.5 10.4996V9.30195L1.9125 9.71211C3.96328 11.7605 7.28672 11.7605 9.33516 9.71211C9.90703 9.14023 10.3219 8.46758 10.575 7.75039C10.7133 7.35898 10.507 6.93242 10.118 6.79414C9.72891 6.65586 9.3 6.86211 9.16172 7.25117C8.98125 7.76211 8.68828 8.24258 8.27578 8.65273C6.81094 10.1176 4.43672 10.1176 2.97188 8.65273L2.96953 8.65039L2.56875 8.24961H3.75C4.16484 8.24961 4.5 7.91445 4.5 7.49961C4.5 7.08477 4.16484 6.74961 3.75 6.74961H0.759375C0.721875 6.74961 0.684375 6.75195 0.646875 6.75664C0.609375 6.76133 0.574219 6.76836 0.539062 6.78008Z"
        fill="#32A467"
      />
    </g>
    <defs>
      <clipPath id="clip0_2813_23756">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
